import React, { Component } from "react";
import { Link } from "react-router-dom";
import { message, Popconfirm, Table, Switch, Input, Button, Space, Tag, Modal } from "antd";
import { MdDelete, MdEdit, MdRemoveRedEye, MdContentCopy } from "react-icons/md";
import Http from "../../services/Http";
import ContentLoader from "../../components/loaders/ContentLoader";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

dayjs.extend(relativeTime);

class Records extends Component {
  state = {
    customers: [],
    selected: [],
    load: false,
    searchText: "",
    searchedColumn: "",
    modal: {},
  };

  // rowSelection object indicates the need for row selection
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ selected: selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  onClose = () => {
    this.setState({ modal: { open: false } });
  };

  onOpen = () => {
    this.setState({ modal: { open: true } });
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Http.get("customers").then((res) => {
      res.data.customers.map((customer) => (customer.key = customer._id));

      let customersData = res.data.customers;
      // Robust sorting function that handles possible missing dates
      customersData.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        if (!dateA.getTime()) return 1; // Assume `a` goes after `b` if `a.createdAt` is invalid
        if (!dateB.getTime()) return -1; // Assume `a` goes before `b` if `b.createdAt` is invalid
        return dateB - dateA; // Otherwise, sort by date descending
      });

      this.setState({
        customers: customersData,
        load: true,
      });
    });
  };

  toggleActive = (value) => {
    Http.post("customers/" + value + "/toggleActive")
      .then((res) => {
        message.success("customer has been toggle activate");
      })
      .catch((error) => message.warning(error.response.data.message));
  };

  deleteRecord = async (id) => {
    await Http.delete("customers/" + id)
      .then((res) => {
        // this.getData();
        this.setState({ customers: this.state.customers.filter((customer) => customer._id != id) });
        message.success("customer has been deleted");
      })
      .catch((error) => message.warning(error.response.data.message));
  };

  deleteMany = () => {
    this.state.selected.map((itemId) => this.deleteRecord(itemId));
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const { customers, load } = this.state;

    const columns = [
      {
        title: "Date",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => <span>{dayjs(text).fromNow()}</span>,
      },
      {
        title: "End",
        dataIndex: "endPackage",
        key: "endPackage",
        render: (text) => (
          <span>
            {text.subscribe ? (
              dayjs(text.date).fromNow().includes("ago") ? (
                <Tag color="red">{dayjs(text.date).fromNow()}</Tag>
              ) : (
                <Tag color="blue">{dayjs(text.date).fromNow()}</Tag>
              )
            ) : dayjs().isAfter(dayjs(text.date).add(14, "day")) ? (
              <Tag color="red">Expire</Tag>
            ) : (
              <Tag color="blue">free trial {dayjs(text.date).add(14, "day").fromNow()}</Tag>
            )}
          </span>
        ),
      },
      // {
      //   title: "logo",
      //   dataIndex: "logo",
      //   key: "logo",
      //   render: (text, record) => (
      //     <img
      //       width="50"
      //       alt={record.subdomain}
      //       src={text ? Http.front_url + text : "/img/logo.png"}
      //     />
      //   ),
      // },
      {
        title: "subdomain",
        dataIndex: "subdomain",
        key: "subdomain",
        ...this.getColumnSearchProps("subdomain"),
      },
      // {
      //   title: "Admin",
      //   dataIndex: "adminName",
      //   key: "adminName",
      // },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ...this.getColumnSearchProps("email"),
      },
      {
        title: "category",
        dataIndex: "category",
        key: "category",
        render: (text) => <span>{text}</span>,
      },
      {
        title: "Plan",
        dataIndex: "plan",
        key: "plan",
      },
      {
        title: "phone",
        dataIndex: "phone",
        key: "phone",
        ...this.getColumnSearchProps("phone"),
      },

      {
        title: "Active",
        dataIndex: "isActive",
        key: "isActive",
        render: (text, record) => (
          <span>
            <Switch defaultChecked={text} onChange={(value) => this.toggleActive(record._id, value)} />
          </span>
        ),
      },

      {
        title: "action",
        key: "action",
        render: (text, record) => (
          <span>
            <a
              href={
                record.domain
                  ? "http://" + record.domain
                  : `https://${record.subdomain}.${window.location.href.includes("motajer.net") ? "motajer.shop" : "motajerbot.com"}`
              }
              target="_blank"
              rel="noreferrer"
              className="btn btn-sm btn-primary"
            >
              <MdRemoveRedEye />
            </a>

            <Link to={`/panel/customers/${record._id}/edit/`} className="btn btn-sm btn-warning">
              <MdEdit />
            </Link>

            <Link to={`/panel/customers/${record._id}/duplicate/`} className="btn btn-sm btn-success">
              <MdContentCopy />
            </Link>

            <Popconfirm title="Are you sure delete this?" onConfirm={() => this.deleteRecord(record._id)} okText="Yes" cancelText="No">
              <button className="btn btn-sm btn-danger">
                <MdDelete />
              </button>
            </Popconfirm>
          </span>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="all-categories admin-card pd">
          <div className="admin-title">
            <h6>customers</h6>
            <Link to="/panel/customers/add" className="btn btn-primary">
              New customer
            </Link>

            {this.state.selected?.length > 0 && (
              <Popconfirm title="Are you sure delete this?" onConfirm={() => this.deleteMany()} okText="Yes" cancelText="No">
                <button className="btn btn-sm btn-danger">
                  <MdDelete />
                </button>
              </Popconfirm>
            )}
          </div>

          {load && (
            <Table
              columns={columns}
              dataSource={customers}
              rowSelection={{
                type: "checkbox",
                ...this.rowSelection,
              }}
              pagination={{
                defaultPageSize: 30,
                showSizeChanger: true,
                position: "both",
              }}
            />
          )}
          {!load && <ContentLoader />}
        </div>
      </React.Fragment>
    );
  }
}

export default Records;
